import { HttpHandler } from '@angular/common/http';
import { Utils } from './../../../shared/utils/utils';
import { Observable, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceUtils } from 'src/app/shared/utils/service-utils';
import { catchError } from 'rxjs/operators';
import { BaseResponse } from 'src/app/indicator/services/interface/base-response-dto';
import { WebPortalRegisteredUsers } from 'src/app/shared/objects/WebPortalRegisteredUsers';

@Injectable()
export class RegisteredUsersService{


    constructor(private http: HttpClient){}

    public getRegisterdEmployeeUsers():Observable<any>{
        const url = ServiceUtils.REGISTERED_USERS_URL;
       return this.http.get<any>(url).pipe(catchError(ServiceUtils.handleError));
    }

    public getRegisterdEmployeeUser(selectedId):Observable<any>{
        const url = ServiceUtils.REGISTERED_USERS_URL + "/" + selectedId;
        return this.http.get<any>(url).pipe(catchError(ServiceUtils.handleError));
    }

    public changeStatus(selectedUserId , status :string , rejectionReason?): Observable<any>{
        const url = ServiceUtils.REGISTERED_USERS_CHANGE_STATUS_URL;
        return this.http.post<any>(url,{id : selectedUserId ,status : status.toUpperCase() , rejectionReason:rejectionReason}).pipe(catchError(ServiceUtils.handleError));
    }

    public viewAttachment(userId): Observable<any>{
        const url = ServiceUtils.REGISTERED_USERS_VIEW_ATTACH_URL + "/" + userId;
        return this.http.get<any>(url).pipe(catchError(ServiceUtils.handleError));
    }

    public enableLogin(userId:number){
        const url = ServiceUtils.REGISTERED_USERS_ENABLE_LOGIN_URL + "/" + userId;
        return this.http.get<BaseResponse<WebPortalRegisteredUsers>>(url).pipe(catchError(ServiceUtils.handleError));
    }

    public deleteUser(id:number): Observable<any> {
        const url = ServiceUtils.DELETE_WEBPORTAL_USERS_URL;
        return this.http.get<BaseResponse<WebPortalRegisteredUsers>>(url + `/${id}`).pipe(catchError(ServiceUtils.handleError));
    }
}