
export class RegexUtils{

    public static URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ ; 
    public static EMAIL_REGEX = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    public static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,10}$/

    public static AT_LEAST_UPPER = /^(?=.*[A-Z])/;
    public static AT_LEAST_LOWER = /^(?=.*[a-z])/;
    public static AT_LEAST_DIGIT = /^(?=.*\d)/;
    public static AT_LEAST_SPECIAL = /[@_&-,.$!]+/;

    public static isValid(regex, value){
        return regex.test(value);
    }
}