import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SanitizeHtmlService {

  constructor(private sanitizer: DomSanitizer) { 
    
  }
  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  toPlainText(html: string): string {
    return html ? html.replace(/<[^>]*>/g, '') : '';
  }
  formatText(text: string, maxLength: number): SafeHtml {
    const truncatedText = this.truncateText(text, maxLength);
    return this.sanitizer.bypassSecurityTrustHtml(truncatedText);
  }

  // Truncate helper method
  private truncateText(text: string, maxLength: number): string {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }
}
