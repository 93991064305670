import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private sidebarToggled = new BehaviorSubject<boolean>(false);
  sidebarToggled$ = this.sidebarToggled.asObservable();

  toggleSidebar() {
    this.sidebarToggled.next(!this.sidebarToggled.value);
  }

  setSidebarState(state: boolean) {
    this.sidebarToggled.next(state);
  }
}
